/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { createRef, useState } from 'react';
import './OurServices.scss';

import { Col, Container, Row } from 'react-bootstrap';
import { faClock, faFolderOpen, faHandshake } from '@fortawesome/free-regular-svg-icons';
import { faFileInvoiceDollar, faPlaneDeparture, faSearchDollar, faServer, faUserInjured, faUsersBetweenLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const contractComplianceBody = [
`Your contract IS your business. Plus, it’s your model for running a successful 
business. A critical role of TTA’s bundled back-office services is to ensure 
compliance and assist with audits.`]

const hrManagementBody = [
    `Hiring and retaining great talent is invaluable to your business. Equally as important
    is creating the capacity to manage your staff.`,
    `With trained professionals, we go the extra mile to ensure you have the HR support 
    you need to manage your team. We’re prepared to provide proactive and actionable
    steps to mitigate, if not fully avoid, legal exposure, as well as minimize risks and 
    keep your profit margins protected.`, 
    `We provide guidance and support on employee relations issues, from employee 
    handbooks, unemployment and EEOC to matters like workplace harassment, 
    discrimination and retaliation. All calls returned within 24 hours. We’ll provide 
    information and options, and explain the potential consequences of the decisions 
    you make.`,
    `Going it alone can quickly turn a routine issue into a complicated and costly legal 
    matter. Instead, leverage the HR expertise of TTA and let us empower you with the 
    tools to navigate the complex world of workplace law and compliance.`
]

const payrollBody = [
    `As a last-mile delivery contracted service provider, you understand the importance 
    of efficiency and reliability in your daily operations. With tight delivery deadlines 
    and high-volume workloads, your time can be better spent elsewhere than on 
    managing payroll complexities. That's where outsourcing this function to a specialist
    like TTA can make all the difference.`,
    `By entrusting your payroll administration to TTA, you can free up valuable time and 
    resources that can be spent on managing and growing your business. With 
    extensive knowledge and experience in administering payroll for last-mile delivery 
    contractors, we can ensure accurate and timely payroll service to your employees 
    while also handling all necessary tax filings.`,
    `Avoiding penalties and fines for missed or incorrect tax filings is crucial for any 
    business. With TTA, you can be assured that all local, state, and federal tax filings 
    will be completed correctly and submitted in a timely manner.`,
    `Additionally, our detailed reporting and record-keeping allow you to easily track 
    employee payments and expenses, allowing for better financial management and 
    decision-making and contract compliance. Don't let the stress of managing payroll take away from your focus on delivering 
    exceptional last-mile services. TTA is your reliable payroll partner.`
]

const compensationBody = [
`With the rising complexity of workers' compensation claims and the potential for 
fraudulent claims, it can be overwhelming to handle these issues on your own. 
That's where trusting this critical human resources function to a specialist like TTA 
can provide immense value.`,
`Our in-house HR professionals have a strong command of local laws and regulations
regarding workers' compensation, and put that knowledge to use for our clients, 
ensuring that all claims and hearings are handled promptly and accurately.`,
`We’re particularly adept at protecting clients from potentially fraudulent claims. 
With a skilled HR team at your disposal, they meticulously review each claim and 
investigate any suspicious activity, reducing the risk of false claims damaging your 
business and needlessly adding to your costs.`,
`Moreover, partnering with TTA gives clients access to a wealth of resources and 
expertise. We help you improve safety protocols within your operations, thereby 
reducing the likelihood of accidents and subsequent claims. With TTA as your 
trusted partner, you can focus on growing your business.`
]

const accountingBody = [
`Last mile delivery contractors understand the importance of operating within a 
budget. This data not only helps you manage expenses and stay on top of your 
budgeting, but it also plays a crucial role when it comes time for annual audits of 
your contract.`,
`We’re uniquely experienced in the last-mile delivery industry. Engaging with TTA to 
manage your accounting and bookkeeping support functions can be incredibly 
beneficial to contractors. Clients who used to go it alone with accounting services 
tell us they now both recognize and value what we’re able to take off their plate 
after having tried to do it themselves. Gain back that time on your schedule so you 
can focus on other business-building matters. `,
`Already have a relationship with a strong accountant? No worries. We’ll work to 
make their job that much easier by providing them the records they need precisely 
when they need them.`
]

const techBody = [
`Enjoy administrative efficiencies while streamlining your business with TTA’s 
automated software and IT solutions. Our easy-to-use web application simplifies 
payroll, injury reporting, real-time budgeting and more.`,
`We are all about convenience and functionality, so we have developed software that
helps you manage your operations effortlessly from anywhere and with minimal 
involvement. Take payroll, for instance. Spend 10 minutes a day on your end 
validating inputs, and we automate the rest with precision and accuracy, all the way
to your drivers receiving their direct deposits and your local, state and federal 
withholdings being paid when they’re due.`,
`Our software tools are suited for any last mile delivery business, including owners 
who are growth-minded or already have multiple CSAs.`,
`Ensure that your team is always on the same page, minimize communication errors 
and reduce the risk of costly mistakes. Our goal is to protect – and grow – your 
margins. And we’re constantly developing technology solutions to make this 
possible.`
]



export default class OurServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.refArray = Array.from(Array(6).keys()).map((index) => {
            return createRef();
        })
    }

    render() {  
        return (
            <div>
                <h1 className='main-header'>Our Services</h1>
                <OurServicesOverview refArray={this.refArray}/>
                <section className='service-section'>
                    <OurServicesSection innerRef={this.refArray[0]} id='contract-compliance' title='Contract Compliance' subtitle='Your Contract is Your Map to Success' description={contractComplianceBody} number='1' color='var(--bs-primary)' icon={faHandshake}/>
                    <OurServicesSection innerRef={this.refArray[1]} id='hr' title='HR Management' subtitle={`Navigate the Complex World of Workplace Law and Compliance with TTA's HR Support`} description={hrManagementBody} number='2' color={'var(--bs-secondary)'} icon={faUsersBetweenLines}/>
                    <OurServicesSection innerRef={this.refArray[2]} id='payroll' title='Payroll & Tax' subtitle={`Take the Stress Out of Payroll and Tax Filings with Expert Outsourced Services`} description={payrollBody} number='3' color='var(--bs-primary)' icon={faFileInvoiceDollar}/>
                    <OurServicesSection innerRef={this.refArray[3]} id='bwc' title={`Workers' Compensation`} subtitle={`Let Us Maintain Control of Your Workers' Compensation Costs`} description={compensationBody} number='4' color={'var(--bs-secondary)'} icon={faUserInjured}/>
                    <OurServicesSection innerRef={this.refArray[4]} id='accounting' title='Strategic Accounting' subtitle={`Strategic Budgeting/Accounting Services Tailored to the Last-Mile Delivery Industry`} description={accountingBody} number='5' color='var(--bs-primary)' icon={faSearchDollar}/>
                    <OurServicesSection innerRef={this.refArray[5]} id='technology' title='Cutting-edge Software' subtitle={`Streamline Your Business with State-of-the-Art Technology`} description={techBody} number='6' color={'var(--bs-secondary)'} icon={faServer}/>
                </section>
            </div>
        )
    }
}


class OurServicesOverview extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            windowSize: window.innerWidth,
        }
    }

    componentDidMount() {
        window.addEventListener('resize', ()=>this.setState({windowSize: window.innerWidth}));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', ()=>this.setState({windowSize: window.innerWidth}));
    }

    render() {
        return (
            <div className='overview-card-container'>
                    <div className='row'>
                        <OurServicesOverviewItem
                            className={'primary'}
                            innerRef={this.props.refArray[0]}
                            number={1}
                            icon={faHandshake} 
                            title={'Contract Compliance'} 
                            description={'Processing of required contractor annual certifications and handling of contractor payroll and administrative audits.'}
                            />
                        <OurServicesOverviewItem
                            className={'secondary'}
                            innerRef={this.props.refArray[1]}
                            number={2}
                            icon={faUsersBetweenLines} 
                            title={'Human Resource Management'} 
                            color
                            description={'Human resource policy development and implementation in compliance with federal and state employment law.'}
                            />
                    </div>
                    <div className='row'>
                        <OurServicesOverviewItem
                            className={ this.state.windowSize < 1400 ? 'primary' : 'secondary'}
                            number={3}
                            innerRef={this.props.refArray[2]}
                            icon={faFileInvoiceDollar} 
                            title={'Payroll Processing and Tax Compliance'} 
                            description={'Payroll processing and tax compliance including tax reporting and payment of applicable federal, state, and local payroll taxes.'}
                            />
                        <OurServicesOverviewItem
                            className={this.state.windowSize < 1400 ? 'secondary' : 'primary'}
                            innerRef={this.props.refArray[3]}
                            number={4}
                            icon={faUserInjured} 
                            title={'Workers Compensation and Unemployment'} 
                            description={'Claims and coverage administration assistance and defense.'}
                            />
                    </div>
                    <div className='row'>
                        <OurServicesOverviewItem
                            className={'primary'}
                            innerRef={this.props.refArray[4]}
                            number={5}
                            icon={faSearchDollar} 
                            title={'Strategic Accounting and Tax Services'} 
                            description={'Business and personal tax preparation, tax planning, and business budgeting.'}
                            />
                        <OurServicesOverviewItem
                            className={'secondary'}
                            number={6}
                            innerRef={this.props.refArray[5]}
                            icon={faServer} 
                            title={'Cutting-edge Software'} 
                            description={'Mobile time keeping, scheduling, roster maintenance, automated payroll onboarding, FLSA compliant payroll submissions, and up-to-date financial and budgeting tools.'}
                            />
                    </div>
            </div>
        )
    }
}

function OurServicesOverviewItem({description, icon, innerRef, title, number, className}){

    return (
        <Col xxl={6} style={{padding: 6}}>
            <div className={className} onClick={() => {
                innerRef.current.scrollIntoView({behavior: "smooth"});
            }}>
                <FontAwesomeIcon className='svg' style={{aspectRatio: 1}} icon={icon} size='3x'/>
                <div className='overview-card-text'>
                    <h4 className='overview-card-header'>{number}. {title}</h4>
                    <span>{description}</span>
                </div>
            </div>
        </Col>
    )
}


function OurServicesSection({title, subtitle, description, number, color, innerRef, id, icon}) {
  return (
    <div ref={innerRef} id={id} className='service-section-container'>
        <div className='service-container'>
            <div className='svg-service-container' style={{backgroundColor: color}}>
                <h2 className='svg-service-container-text'>{title}</h2>
                <FontAwesomeIcon className='svg-service' style={{aspectRatio: 1}} icon={icon}/>
                <div className='number-circle' style={{color: color, border: `5px solid ${color}`}}>
                    {number}
                </div>
            </div>
            <div className='service-text-container'>
                <h1 className='service-header'>
                    {subtitle}
                    <div className='before' style={{backgroundColor: color}}></div>
                    <div className='after' style={{backgroundColor: color}}></div>
                </h1>
                <div className='service-text'>
                    {description.map((p)=>{
                        return <p key={p}>
                            {p}<br/><br/>
                        </p>
                    })}
                </div>
            </div>
        </div>
    </div>
  )
}

export function OurServicesCard({title, icon, body}) {
    return (
        <Col lg={6} style={{padding: 20}}>
            <div style={{position: 'relative', backgroundColor: 'white', borderRadius: 12, aspectRatio: 2, boxShadow: '0px 5px 8px 0px black'}}>
                <div className='truck-tiles' style={{position: 'absolute', height: '100%', width: '100%', opacity: 0.05, borderRadius: 12}}/>
                <div style={{padding: 24}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 18, marginBottom: 24}}>
                        <FontAwesomeIcon size='2x' icon={icon}/>
                        <h3 style={{marginBottom: 0}}>{title}</h3>
                    </div>
                    <p style={{opacity: 0.75}}>{body}</p>
                </div>
            </div>
        </Col>
    )
}