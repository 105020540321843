/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import ErrorPage from './views/ErrorPage/ErrorPage';
import Home from './views/Home/Home';
import AboutUs from './views/AboutUs/AboutUs';
import PrivacyPolicy from './views/PrivacyPolicy';
import TermsAndConditions from './views/TermsAndConditions';
import OurServices from './views/OurServices/OurServices';
import Pricing from './views/Pricing/Pricing';
import NotFound from './views/NotFound/NotFound';
import OurSites from './views/OurSites/OurSites';
import Recruiting from './views/Recruiting/Recruiting';

export default function Router() {


    const routes = [
        {
            title: 'Tactical Transportation Advisors',
            path: '/',
            component: <Home/>
        },
        {
            title: 'TTA - About Us',
            path: '/about',
            component: <AboutUs/>
        },
        {
            title: 'TTA - Pricing',
            path: '/pricing',
            component: <Pricing/>
        },
        {
            title: 'TTA - Recruiting',
            path: '/recruiting',
            component: <Recruiting/>
        },
        {
            title: 'TTA - Contract Compliance',
            path: '/services',
            component: <OurServices/>
        },
        {
            title: 'TTA - Our Sites',
            path: '/oursites',
            component: <OurSites/>
        },
        {
            title: 'TTA - Privacy Policy',
            path: '/privacy',
            component: <PrivacyPolicy/>
        },
        {
            title: 'TTA - Terms & Conditions',
            path: '/termsofservice',
            component: <TermsAndConditions/>
        },
    ]

    const routeElements = routes.map((route) => {
        return (
            <Route key={route.title} exact path={route.path} render={props => (
                <Page key={route.title} title={route.title}>
                    {route.component}
                </Page>
            )}/>
        )
    })


    return (
        <Switch>
            {routeElements}
            <Route key={'Not Found'} exact path={'*'} render={props => (
                <Page key={'notFound'} title={'Not Found'}>
                    <NotFound/>
                </Page>
            )}/>
        </Switch>
    )
}

class Page extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = this.props.title;
    }

    render() {
        return (
            <>{this.props.children}</>
        )
    }
}