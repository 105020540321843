/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';

import teamLogo from '../../assets/img-team-2.png';
import { Col, Container, Row } from 'react-bootstrap';
import './AboutUs.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default function AboutUs() {

    const leaderDescriptions = [
        `Tom lends his expertise in contract and workplace law, as well as his more than 30 
        years in the trucking and last-mile delivery industry to TTA clients. Tom has worked 
        at virtually every level of the industry, from driver to manager, contractor, attorney 
        and business advisor for organizations like Roadway Express, DHL and FedEx.`,
        `Mark leads the financial and accounting sides of TTA’s client service offerings. He 
        has decades of senior leadership experience in accounting, tax planning and 
        compliance and financial planning in a variety of industries.`,
        `Camron leads the technology initiatives at TTA, specifically the development of web 
        and mobile interactive applications and automation tools to help independent 
        contractors run their businesses most efficiently and accurately. He’s led IT projects
        in both the last-mile delivery and healthcare industries.`,
        `Randy leads our employment/workplace law service area providing legal counsel to 
        independent contractors, as well as educating and preparing independent 
        contractor clients to avoid legal exposure. He’s worked several years in a similar 
        capacity in the last-mile delivery industry, including two years delivering and 
        managing for DHL.`
    ]

    const aboutUsText = [
        {
            heading: 'Unmatched last-mile delivery industry expertise',
            body: `The last-mile delivery ecosystem is evolving. Now more than ever, your ability to 
            effectively manage and operate your business and your people is vital. For 
            authorized officers looking to scale their operations, having the right back-office 
            partner can make the difference between your profit margins sinking or swimming. 
            Your cost structure model accounts for you to pay for back-office services. Let us be
            that partner working on your behalf, protecting your margins and providing bottom-
            line savings.
            As your trusted ally, Tactical Transportation Advisors becomes your extended back-
            office team, providing HR, legal, payroll, technology, workers’ compensation support
            and more. Our job is to assure that your business’ workflow functions efficiently and
            without unnecessary costs. We combine technology and unmatched last-mile 
            delivery industry expertise to deliver measurable results for your enterprise. 
            We want you to grow, be profitable and retire a millionaire. Our services are 
            designed specifically to free your time to manage your business and your 
            relationship with your delivery company.`
        },
        {
            heading: 'Maximizing your contract values',
            body: `For more than 20 years, we have worked with last-mile delivery contractors on fully 
            understanding and best managing their budgets. Through innovative technology 
            and a robust network of industry contacts, we can uniquely provide you with access 
            to insights that help you make the best decisions for your business, particularly 
            come contract negotiations time. Our proven contract management model, enabled 
            by our operations framework, means we can analyze and accurately represent your 
            business expenses and offer tailored contract negotiation solutions.`
        },
        {
            heading: 'Consolidated services',
            body: `We take pride in our ability to manage your business’ payroll, HR, IT, legal and 
            compliance needs. It’s all about making life easier for you as the business owner. 
            You focus your efforts on managing your team and delivering a high level of 
            customer service. We’ll take care of the back office.`
        },
    ]

    return (
        <div className='about-section' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', overflowY: 'scroll'}}>
            <h1 className='about-header'>About Us</h1>
            <div style={{width: '100%'}}>
                {/* <Container fluid style={{maxWidth: 1200}}>
                    <Row>
                        <Col lg={7} style={{padding: 20}}>
                            <div style={{position: 'relative', backgroundColor: 'var(--bs-primary)', borderRadius: 12, aspectRatio: 1}}>
                                <div className='truck-tiles' style={{position: 'absolute', height: '100%', width: '100%', opacity: 0.05, borderRadius: 12}}/>
                                <div style={{padding: 32, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'white'}}>
                                    <h1 style={{fontWeight: 'bolder', fontSize: 40}}>Unmatched last-mile delivery industry expertise</h1>
                                    <p style={{fontSize: 17}}>
                                        The last-mile delivery ecosystem is evolving. Now more than ever, your ability to 
                                        effectively manage and operate your business and your people is vital. For 
                                        authorized officers looking to scale their operations, having the right back-office 
                                        partner can make the difference between your profit margins sinking or swimming. 
                                        Your cost structure model accounts for you to pay for back-office services. Let us be
                                        that partner working on your behalf, protecting your margins and providing bottom-
                                        line savings.
                                        <br/>
                                        <br/>
                                        As your trusted ally, Tactical Transportation Advisors becomes your extended back-
                                        office team, providing HR, legal, payroll, technology, workers’ compensation support
                                        and more. Our job is to assure that your business’ workflow functions efficiently and
                                        without unnecessary costs. We combine technology and unmatched last-mile 
                                        delivery industry expertise to deliver measurable results for your enterprise. 
                                        <br/>
                                        <br/>
                                        We want you to grow, be profitable and retire a millionaire. Our services are 
                                        designed specifically to free your time to manage your business and your 
                                        relationship with your delivery company.                                   
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={7} style={{padding: 20}}>
                            <div style={{aspectRatio: 1}}>
                                <img src={manAndTruck} style={{height: '100%', width: '100%', objectFit: 'cover', borderRadius: 12}}/>
                            </div>
                        </Col>
                        <Col lg={6} style={{padding: 20}}>
                            <div style={{aspectRatio: 1}}>
                                <img src={manAndSteeringWheel} style={{height: '100%', width: '100%', objectFit: 'cover', borderRadius: 12}}/>
                            </div>
                        </Col>
                        <Col lg={6} style={{padding: 20}}>
                            <div style={{position: 'relative', backgroundColor: 'var(--bs-secondary)', borderRadius: 12, aspectRatio: 1}}>
                                <div className='truck-tiles' style={{position: 'absolute', height: '100%', width: '100%', opacity: 0.05, borderRadius: 12}}/>
                                <div style={{padding: 32, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'white'}}>
                                    <h1 style={{fontWeight: 'bolder', fontSize: 60}}>Our Mission</h1>
                                    <p style={{fontSize: 20}}>Our mission is to provide cost-effective and comprehensive human resource, payroll, business, tax, and accounting services integrated with state-of-the-art technologies specifically designed for the challenges faced by last mile independent service providers.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container> */}
                <div>
                    <div className='about-container'>
                        <div className='img-container'>
                             {/* <img className='about-img' src={manAndTruck}/> */}
                             {/* <img className='about-img' src={manAndTruck}/> */}
                        </div>
                        <div className='about-text-container'>
                        <   div>
                                <h2 className='text-header'>Working with TTA</h2>
                                <p className='text-p'>
                                    Are you the type of owner that prefers to be hands-off and manage your business at
                                    arm’s length? Are you looking to expand your CSA or add new territories? Or are 
                                    you actively managing your business? Either way, we’re here to help provide value.s
                                    <br/>
                                    <br/>
                                    We specialize in last-mile delivery, and we’re driven to help you protect your 
                                    margins. We provide capable, responsive HR and contract management, 
                                    accounting/tax, payroll, and software all in one affordable bundle.
                                    <br/>
                                    <br/>
                                </p>
                            </div>
                            <div>
                                <h2 className='text-header'>Unmatched last-mile delivery industry expertise</h2>
                                <p className='text-p'>
                                    The last-mile delivery ecosystem is evolving. Now more than ever, your ability to 
                                    effectively manage and operate your business and your people is vital. For business
                                    owners looking to scale their operations, having the right back-office partner can 
                                    positively impact your profit margins. Let us work on your behalf, protect your 
                                    margins and deliver bottom-line savings.
                                    <br/>
                                    <br/>
                                    As your trusted vendor, Tactical Transportation Advisors becomes your extended 
                                    back-office team, providing HR, payroll, technology, workers’ compensation support 
                                    and more. Our job is to relieve you of burdensome operations management. We 
                                    combine technology and unmatched last-mile delivery industry expertise to deliver 
                                    measurable results for your enterprise.
                                    <br/>
                                    <br/>
                                    We carefully price our services within your budget. By bundling the entirety of your 
                                    HR, workers’ compensation and payroll with TTA, we’re able to efficiently organize 
                                    your business. We can position you most favorably to make data-driven and timely 
                                    business decisions while always protecting your margins.
                                </p>
                            </div>
                            <div>
                                <h2 className='text-header'>Maximizing your contract values</h2>
                                <p className='text-p'>
                                For more than 25 years, we have worked with last-mile delivery contractors on fully 
                                understanding and managing their budgets. Through innovative technology and a 
                                network of industry contacts, we can uniquely provide you with access to insights 
                                that help you make the best decisions for your business come contract negotiation 
                                time.
                                </p>
                            </div>
                            <div>
                                <h2 className='text-header'>Consolidated services</h2>
                                <p className='text-p'>
                                We take pride in our ability to manage your business’ payroll, HR and compliance 
                                needs. You focus your efforts on managing your team and delivering a high level of 
                                customer service. We’ll take care of the back office. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='leadership-container'>
                    <h1 className='leadership-container-header'>Our Leadership Team</h1>
                    <div className='grid'>
                        <LeaderCard name={'Thomas N. Trefethern, Esq.'} title={'Co-Owner and President'} email={'tntrefethern@ttransadvisors.com'} description={leaderDescriptions[0]} picture={defaultPic}/>
                        <LeaderCard name={'Mark F. Tate, CPA, DBA'} title={'Co-Owner and Chief Financial Officer'} email={'mtate@tatecpaadvisors.com'} description={leaderDescriptions[1]} picture={defaultPic}/>
                        <LeaderCard name={'Camron D. Shaw'} title={'Director of Information Technology'} email={'cshaw@ttransadvisors.com'} description={leaderDescriptions[2]} picture={defaultPic}/>
                        <LeaderCard name={'Randal J. Trefethern, Esq.'} title={'Director of Legal Services'} email={'rjtrefethern@ttransadvisors.com'} description={leaderDescriptions[3]} picture={defaultPic}/>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

function TeamCol({name, title, email, description}) {
    return (
        <Col lg={6} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'white', fontSize: 18, marginBottom: 32}}>
            <span style={{whiteSpace: 'nowrap', fontWeight: 'bold'}}>{name}</span>
            <span style={{fontStyle: 'italic', opacity: 0.6}}>
                {title}
                { email &&
                    <>
                        {' | '}
                        <a style={{color: 'white'}} href={`mailto:${email}`}>Email</a>
                    </>
                }
            </span>
            <div>
                <p style={{textAlign: 'left'}}>
                    {description}
                </p>
            </div>
        </Col>
    )
}

function LeaderCard({name, email, description, picture, title}) {
  return (
      <div className='leadership-card'>
        <div className='leadership-card-background'></div>
        <div className='leadership-row'>
            <div className='leadership-info'>
                <h3 className='leadership-name'>{name}</h3>
                <h6 className='leadership-title'>{title}</h6>
                <div className='email-row'>
                    <FontAwesomeIcon className='email-svg' icon={faEnvelope}/>
                    <a href={`mailto:${email}`} className='leadership-email'>{email}</a>
                </div>
            </div>
        </div>
        <div>
            <p className='leadership-description'>
                {description}
            </p>
        </div>
    </div>
  )
}
