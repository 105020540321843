/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './App.css';
import React from 'react';
import NavigationBar from './navigation/NavigationBar/NavigationBar';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';
import Footer from './components/Footer/Footer';

export default function App() {
  return (
    // <div className="App" style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
        <BrowserRouter>
            <NavigationBar/>
            <div style={{flex: 1, top:65, position: 'relative'}}>
                <Router/>
                <Footer/>
            </div>
        </BrowserRouter>
    // </div> 
  )
}

