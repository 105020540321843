/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import { NavHashLink } from "react-router-hash-link";
import '../NavigationBarLink/NavigationBarLink.scss'

export const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'instant'})
}

export default function NavigationBarLink({title, destination}) {
    return (
        <NavHashLink onClick={scrollToTop} style={{whiteSpace: 'nowrap'}} className={'nav-bar-link'} to={destination}>
            {title}
        </NavHashLink>
    );
}


export function DropdownLink({title, destination, hasActiveClass}) {
    return (
        <NavHashLink onClick={scrollToTop} style={{whiteSpace: 'nowrap'}} className={'dropdown-link'}  activeClassName={hasActiveClass ?"dropdown-link-active" : ''} to={destination}>
            <p style={{padding: '4px 0px 4px 20px', marginBottom: 0}}>
                {title}
            </p>
        </NavHashLink>
    );
}
