import React from 'react'
import './Pricing.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBuilding } from '@fortawesome/free-solid-svg-icons';
import {BsCheck2Circle} from 'react-icons/bs'

const pricingCardInfo1 = `Track your employee's time and schedule with our easy to use application.`
const pricingCardInfo2 = `Access full payroll, accounting, and legal services for your last-mile-delivery contracting service.`

const selfManagementList = [
  `Scheduling Tool`,
  `Time Clock`,
  'Employee Reports',
]
const fullList = [
  `We handle payroll`,
  'We handle accounting',
  `We handle your BWC claims`,
  `Electronic Scheduling`,
  `Time Clock`,
  'Automated payroll reports',
  `Automated employee and company reports`
]

const subscribeLink = `https://checkout.stripe.com/c/pay/cs_live_a1zxc54TxYlZJqwyS5E21LJVhTizBa8axrlw5OMU2HsditQUBlAP5AoHRE#fidkdWxOYHwnPyd1blppbHNgWjA0SF9%2FdjRDX1RwXHRUdFNJdUpjVWRuXTN8TGswRGxoPUZ2XzZ1cTRqdzxIVTA8N2cwNn9BcEdcRmwyQmxQNWJQcmp8Tkd0cGt8VjxfRnBsPFxBZ0NqPEBgNTVcS0ZPUTJgSSd4JSUl`
const contactLink = `mailto:contact@ttransadvisors.com'`;

export default function Pricing() {
  return (
    <div className='pricing-container-beta'>
        <h1 className='pricing-header'>Our Pricing Options</h1>
        {/* <stripe-pricing-table pricing-table-id="prctbl_1Ma0kJFZQuYqQqVLPfuF2ZQz"
            publishable-key="pk_live_51MZzs1FZQuYqQqVLpOfPakX6yIn5Aim8CsZ3pt1or9MP592b53zDuBYCi7GiU0gUwoyKBqunyS9ZCui9YDbFo9Ee00YNCJT7eL">
        </stripe-pricing-table> */}
      <div className='pricing-container'>
          <PriceCard tier={'Self Management'} href={subscribeLink} icon={faUser} description={pricingCardInfo1} extra={true} color={'var(--bs-secondary)'} price={'$20'} buttonText='Subscribe' listItems={selfManagementList}/>
          <PriceCard tier={'Full Service'} href={contactLink} icon={faBuilding} description={pricingCardInfo2} color={'var(--bs-primary'} buttonText='Contact Us' listItems={fullList}/>
      </div>
    </div>
  )
}


function PriceCard({tier, description, icon, color, price, buttonText, listItems, extra, href}) {
  return (
    <div className='price-card'>
      <div className='color-header' style={{backgroundColor: color}}></div>
        <FontAwesomeIcon className='svg-icon' style={{color: color}} icon={icon}/>
        <h4 className='price-header'>{tier}</h4>
        <p className='price-description'>{description}</p>
        <div style={{height: 130}}>
          <div className='number-container'>
            <h1 className='price'>{price}</h1>
            {extra &&
              <div className='column'>
                  <p className='extra-text per'>per Unit</p>
                  <p className='extra-text per'>per Month</p>
              </div>
            }
          </div>
            {extra &&
              <p style={{textAlign: 'center', }} className='extra-text'>Billed monthly based on usage</p>
            }     
          </div>
          <button style={{backgroundColor: color}} className='price-btn'>
            <a className='btn-text' href={href}>
              {buttonText}
            </a>
          </button>
        <div className='list-container'>
          <p className='list-header'>This includes:</p>
          <ul className='list'>
            {listItems.map(item=>{
              return <li className='list-item' key={item}>
                <span><BsCheck2Circle size={'20px'} style={{color: color}} className='checkmark-svg'/></span>
                <span>{item}</span>
                </li>
            })}
          </ul>
        </div>
    </div>
  )
}
