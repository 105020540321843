/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import './Footer.scss'
import { scrollToTop } from '../../navigation/NavigationBarLink/NavigationBarLink';

export default function Footer() {

    return (
        <footer className='footer'>
            <div className='footer-container'>
                <div className='info-container'>
                    {/* <span>4615 W Streetsboro Rd. STE 203, Richfield, OH 44236</span>
                    <span>+1 (216) 903-2147</span>
                    <a style={{color: 'white', textDecoration: 'none'}} href='email:contact@ttransadvisors.com'>contact@ttransadvisors.com</a> */}
                </div>
                <div className='info-container'>
                    <NavLink onClick={scrollToTop} style={{color: 'white', textDecoration: 'none'}} exact to={'/privacy'}>
                        Privacy Policy
                    </NavLink>
                    <NavLink  onClick={scrollToTop} style={{color: 'white', textDecoration: 'none'}} exact to={'/termsofservice'}>
                        Terms & Conditions
                    </NavLink>
                    <span>{`Copyright © ${moment().format('YYYY')}`} <i style={{opacity: 0.8}}>&nbsp;Tactical Transportation Advisors, Inc.</i></span>
                </div>
            </div>
        </footer>
    )
}