/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react"
import '../OurSites/OurSites.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarXmark, faClock, faFolderOpen, faUser } from "@fortawesome/free-regular-svg-icons"
import { faCalendar, faDollarSign, faGripLines, faRightFromBracket, faUsers, faUsersBetweenLines } from "@fortawesome/free-solid-svg-icons"
import logo from '../../assets/TTA_1024x1024.png'

export default function OurSites(){

// const mtbList = [
//     {
//         name: 'Profile:',
//         text: `View personal and pay information.`,
//         icon: faUser
//     },
//     {
//         name: 'My Schedule:',
//         text: `View personal calendar of scheduled shifts, time off, etc.`,
//         icon: faCalendar
//     },
//     {
//         name: 'Days Off:',
//         text: `View time-off requests and their statuses. Request time off.`,
//         icon: faCalendarXmark
//     },
//     {
//         name: 'My Documents:',
//         text: `View signed documents and acknowledged company documents.`,
//         icon: faFolderOpen
//     },
//     {
//         name: 'My Company:',
//         text: `View/Edit company profile and settings. Manage locations, devices and company documents.`,
//         icon: faUsers
//     },
//     {
//         name: 'Scheduling:',
//         text: `Powerful yet easy-to-use employee scheduling tool.`,
//         icon: faGripLines
//     },
//     {
//         name: 'Payroll:',
//         text: `A payroll processing tool which utilizes timesheets and pay information to do the heavy-lifting for you.`,
//         icon: faDollarSign
//     },
//     {
//         name: 'Human Resources:',
//         text: `A suite of HR tools to help manage timekeeping, time-off requests, PTO, documents and more.`,
//         icon: faUsersBetweenLines
//     }
// ]

// const tcList = [
//     {
//         name: `Profile:`,
//         text: `View personal and pay information.`,
//         icon: faUser
//     },
//     {
//         name: `Time Clock:`,
//         text: `View personal timesheet records. Clock in and out.`,
//         icon: faClock
//     },
//     {
//         name: `My Schedule:`,
//         text: `View personal calendar of scheduled routes, time off, etc.`,
//         icon: faCalendar
//     },
//     {
//         name: `Days Off:`,
//         text: `View time-off requests and their statuses. Request time off.`,
//         icon: faCalendarXmark
//     },
//     {
//         name: 'My Company:',
//         text: `Manage company CSAs, work areas, and vehicles. Modify company settings.`,
//         icon: faUsers
//     },
//     {
//         name: 'Schedule Match:',
//         text: `Comprehensive tool for scheduling routes.`,
//         icon: faGripLines
//     },
//     {
//         name: 'Payroll:',
//         text: `Effortless tool for approving payroll periods. Also contains a complete history of the company\'s payroll.`,
//         icon: faDollarSign
//     },
//     {
//         name: 'Human Resources:',
//         text: `Collection of tools allowing complete control over company employees as well as detailed data and analytics.`,
//         icon: faUsersBetweenLines
//     }
// ]

const mtbDescription = `Business and employee management software to help power and grow your business. Provides tools to manage your company, employees, scheduling and payroll.`;
const tcDescription = `Custom-built software for managing your last-mile delivery business. Provides tools to manage your employees, scheduling/routes, and payroll.`;

const mtbUrl = `https://mytacticalbusiness.com/`;
const tcUrl = `https://tacticalcontractor.com/`;

    return (
        <div className="oursites-section">
            <h1 className="main-header">Our Sites</h1>
            <div className="sites-container">
                <Site title={'Tactical Contractor'} image={'site-image1'} descritpion={tcDescription} url={tcUrl}/>
                <Site title={'My Tactical Business'} image={'site-image2'} descritpion={mtbDescription} url={mtbUrl}/>
                {/* <Site title={'My Tactical Business'} image={'site-image2'} descritpion={mtbDescription} url={mtbUrl}/>
                <Site title={'Tactical Contactor'} image={'site-image1'} descritpion={tcDescription} url={tcUrl}/> */}
            </div>
        </div>
    )
}


function Site({title, descritpion, url, image}){
    return (
        <div className={`site-card ${image}`}>
            <img className="site-logo" src={logo}/>
            <p className="site-description">{descritpion}</p>
            <a href={url}>
                <button className='custom-btn site-btn'>
                    <FontAwesomeIcon icon={faRightFromBracket} style={{marginRight: 8}}/>
                     {title} Login
                </button>
            </a>
        </div>  
    )
}