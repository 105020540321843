/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useState } from 'react';
import { Carousel, Col, Container, Form, Row } from 'react-bootstrap';
import teamLogo from '../../assets/img-team-2.png';
import lasership from '../../assets/LaserShip_logo.svg_.png';
import dhl from '../../assets/DHL-Logo.png';
import fedex from '../../assets/Fedex-logo.png';
import amazon from '../../assets/Amazon-Logo.png';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import { faFileInvoiceDollar, faPhone, faSearchDollar, faServer, faUserInjured, faUsersBetweenLines, faEnvelope, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Home.scss'
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import soatslide from '../../assets/soatslide.avif'
import financeSlide from '../../assets/financeslide.avif';
import firstSlide from '../../assets/firstSlide.avif'
import secondSlide from '../../assets/secondSlide.avif'
import HubspotContactForm from '../../components/HubspotContactForm/HubspotContactForm';


export default function Home() {

    return (
        <div style={{height: '100%', position: 'relative', display: 'flex', flexDirection: 'column', overflowY: 'scroll'}}>
            <div id='home' style={{position: 'relative', backgroundColor: 'var(--bs-secondary)'}} className='carousel-item-container'>
                <div  style={{position: 'absolute', width: '100%', height: '100%', opacity: 0.05}}/>
                <Carousel interval={12000} className='carousel-item-container' >
                    <Carousel.Item className='carousel-item-container'>
                    <img className='carousel-img' style={{opacity: .6}} src={firstSlide}/>
                        <Carousel.Caption>
                            <h1>25+ Years of Industry Experience</h1>
                            <p className='img-text'>With over 25 years of experience in the small package delivery sector, our staff of HR professionals and accountants are uniquely positioned to provide valuable services and technologies designed to align your business with the budgetary and operational requirements of your service contract.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item  className='carousel-item-container'>
                    <img className='carousel-img' src={secondSlide}/>
                        <Carousel.Caption>
                            <h1>A Comprehensive Solution</h1>
                            <p>Cross-Platform Software Tools for <b>HR</b>, <b>Payroll</b>, <b>Scheduling</b>, <b>Timekeeping</b>, and more which have been tailored to meet the needs of your last mile independent package delivery service.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item className='carousel-item-container' >
                    <img className='carousel-img' style={{opacity: .65}} src={financeSlide}/>
                        <Carousel.Caption>
                            <h1>Financial Mastery</h1>
                            <p></p>
                            <p className='img-text' style={{fontStyle: 'italic'}}>By entrusting your payroll administration to TTA, you can free up valuable time and resources that can be spent on serving your customers and growing your business. With extensive knowledge and experience in administering payroll for last-mile delivery contractors, we can ensure accurate and timely payments to your employees while also handling all necessary tax filings.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item  className='carousel-item-container'>
                    <img className='carousel-img' src={soatslide}/>
                        <Carousel.Caption>
                            <h1>State of the Art Technologies</h1>
                            <p >Enjoy best-in-class experiences while streamlining your business with TTA’s automation 
                            software and IT solutions. <b>Our easy-to-use mobile application simplifies</b> <b>route-planning</b>, <b>payroll</b>, <b>injury reporting</b> and <b>real-time budgeting</b></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className='headline-services-container' id='services'>
                <Headline/>
                <OurServices/>
            </div>
            <div id='about'>
                <VisionAndMission/>
            </div>
            <div id='contact'>
                <ContactUs/>
            </div>
        </div>
    )
}

function Headline(){
    return (
        <div className='headline-container'>
            <h1 className='headline-header'>The First Stop for Your Last Mile Delivery</h1>
            <p className='headline-text'>
            Tactical Transportation Advisors powers last-mile delivery businesses forward with 
            our integrated human resources, payroll and accounting back-office professional 
            services. Our bundle of offerings – delivered to your fingertips via powerful, user-
            friendly software – saves time and helps independent contractors manage their 
            business and their employees efficiently and within budget.  
            </p>
            <br/>
            <p className='headline-text'>
                We offer all our services in one convenient package, and it’s all priced within the 
                carrier’s allocated budget.
            </p>
        </div>
    )
}


function OurServices() {
    return (
        <div className='services-container'>
                    <OurServicesItem 
                        icon={faHandshake} 
                        title={'Contract Compliance'}
                        to={'/services/#contract-compliance'}
                        // description={'Processing of required contractor annual certifications and handling of contractor payroll and administrative audits.'}
                    />
                    <OurServicesItem 
                        icon={faUsersBetweenLines} 
                        title={'Human Resource Management'} 
                        color={'var(--bs-secondary)'}
                        to={'/services/#hr'}
                        // description={'Human resource policy development and implementation in compliance with federal and state employment law.'}
                    />
                    <OurServicesItem 
                        icon={faFileInvoiceDollar} 
                        title={'Payroll Processing and Tax Compliance'}
                        to={'/services/#payroll'}
                        // description={'Payroll processing and tax compliance including tax reporting and payment of applicable federal, state, and local payroll taxes.'}
                    />
                    <OurServicesItem 
                        icon={faUserInjured} 
                        title={'Workers Compensation and Unemployment'}
                        color={'var(--bs-secondary)'}
                        to={'/services/#bwc'}
                        //description={'Claims and coverage administration assistance and defense.'}
                    />
                    <OurServicesItem 
                        icon={faSearchDollar} 
                        title={'Strategic Accounting and Tax Services'}
                        to={'/services/#accounting'} 
                        // description={'Business and personal tax preparation, tax planning, and business budgeting.'}
                    />
                    <OurServicesItem 
                        icon={faServer} 
                        title={'Cutting-edge Software '}
                        color={'var(--bs-secondary)'}
                        to={'/services/#technology'}
                        // description={'Mobile time keeping, scheduling, roster maintenance, automated payroll onboarding, FLSA compliant payroll submissions, and up-to-date financial and budgeting tools.'}
                    />
        </div>
    )
}

function OurServicesItem({icon, title, color, to}) {
    return (
        <div className='service-item-row'>
            <FontAwesomeIcon className='service-svg' style={{aspectRatio: 1, backgroundColor: color}} icon={icon}/>
            <div>
                <NavHashLink to={to} className='service-title'>{title}</NavHashLink>
            </div>
        </div>
    )
}

function VisionAndMission() {
    return (
        <div className='vision-mission'>
            <div className='vision-column'>
                <div>
                    <h1 className='vm-header' style={{color: 'var(--bs-primary)'}}>Our Vision</h1>
                    <p className='vm-text'>Our vision is to become a trusted partner and industry leader in providing valuable and cost-effective services and technologies to last mile independent service providers.</p>
                </div>
                <div>
                    <h1 className= 'vm-header' style={{color: '#323743'}}>Our Mission</h1>
                    <p className='vm-text'>Our mission is to provide cost-effective and comprehensive human resource, payroll, business, tax, and accounting services integrated with state-of-the-art technologies specifically designed for the challenges faced by last mile independent service providers.</p>
                </div>
            </div>
            <div className='vm-img-container'>
                <img src={teamLogo} style={{width: '100%'}}/>
            </div>
        </div>
    )
}

function CompanyLogo({image}) {
    return (
        <img src={image} className='company-img'/>
    )
}

function ContactUs() {
  return (
    <div className='contact-container'>
            <div className='colored-background'>
                <h2 className='contact-header'>Contact Us</h2>
                <div className='our-info'>
                    <div className='svg-row'>
                        <FontAwesomeIcon className='contact-svg' icon={faPhone} />
                        <h5 className='svg-text'>+1 (216) 903-2147</h5>
                    </div>
                    <div className='svg-row'>
                        <FontAwesomeIcon className='contact-svg' icon={faEnvelope} />
                        <h5 className='svg-text'>contact@ttransadvisors.com</h5>
                    </div>
                    <div className='svg-row'>
                        <FontAwesomeIcon className='contact-svg' icon={faAddressBook} />
                        <div>
                            <h5 className='svg-text'>4615 W Streetsboro Rd.</h5>
                            <h5 className='svg-text'>STE 203</h5>
                            <h5 className='svg-text'>Richfield, OH 44236</h5>
                        </div>
                    </div>
                </div>
            </div>
            <HubspotContactForm 
                region= "na1"
                portalId = "44519729"
                formId= "861fc363-08dc-4423-af00-99738d1dc9ea"
            />
        </div>
  )
}
