/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faClipboard, faPerson, faRectangleAd, faUserCheck, faUserPen, faUsersGear } from '@fortawesome/free-solid-svg-icons';
import './Recruiting.scss';
import { Button } from 'react-bootstrap';

export default function Recruiting({}){
    const recruitingUrl = 'http://www.ttarecruiting.com';
    return (
        <div style={{minHeight: 'calc(100vh - 125px)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', overflow: 'auto', padding: 18}}>
                <div style={{backgroundColor: 'white', boxShadow: '0 11px 30px rgba(154,161,177,.1)', padding: 12, width: '90%', borderRadius: 8, marginBottom: 28, marginTop: 'auto'}}>
                    <h3 style={{textAlign: 'center', fontWeight: 'bold', marginBottom: 36}}>TTA Recruiting</h3>
                    <div className='we-do-items-container'>
                        <WeDoItem icon={faRectangleAd} title='JOB POSTING' body='We post your jobs to Indeed, Google and ZipRecruiter.'/>
                        <WeDoItem icon={faClipboard} title='JOB POSTING MANAGEMENT' body='Our recruiting specialists will continue to work with you to optimize your job posting specific to your market.'/>
                        <WeDoItem icon={faPerson} title='CANDIDATE SOURCING' body={`We'll generate job applicants and compile them from all job search engines.`}/>
                        <WeDoItem icon={faUserPen} title='CANDIDATE QUALIFICATION' body='We call, text, and email applicants to pre-qualify them and streamline the recruiting process.'/>
                        <WeDoItem icon={faUsersGear} title='FADV MANAGEMENT' body='We provide applicants with the FADV application, assist with drug and physical tests, and actively coordinate with FADV to ensure approval.'/>
                        <WeDoItem icon={faUserCheck} title='READY-TO-GO DRIVERS FOR YOU' body={`Once the applicant passes their drug test, has their MEC file collected, and is background-approved, we'll notify you to proceed with hiring or job discussions.`}/>
                    </div>
                </div>
                <div className='price-cards-container' style={{marginBottom: 'auto'}}>
                    <PriceCard recruitingUrl={recruitingUrl} price={'99'} drivers={3} secondaryPrice={'29.99'}/>
                    <PriceCard recruitingUrl={recruitingUrl} price={'299'} drivers={20} secondaryPrice={'19.99'}/>
                    <PriceCard recruitingUrl={recruitingUrl} price={'999'} drivers={50} secondaryPrice={'9.99'}/>
                    <PriceCard price={'999'} drivers={50} secondaryPrice={'9.99'} items={['Fill and maintain your Full-Time, Part-Time, and AVP Rosters', 'Complete Payroll and HR onboarding', 'Candidate selection and interviewing', 'FADV Management', 'Candidate Sourcing', 'Job Posting Management']}/>
                </div>
            </div>
        </div>
    )
}

function WeDoItem({icon, title, body}){
    return (
        <div className='we-do-item'>
            <div>
                <FontAwesomeIcon style={{width: 24, height: 24}} color='var(--bs-primary)' icon={icon}/>
            </div>
            <div>
                <h6 style={{fontWeight: 'bold'}}>{title}</h6>
                <p>{body}</p>
            </div>
        </div>
    )
}

function PriceCard({price, drivers, secondaryPrice, items, recruitingUrl}){
    const defaultItems = items ?? [`${drivers} Ready-To-Go Drivers`, 'FADV Management', 'Candidate Sourcing', 'Job Posting Management', `$${secondaryPrice} For Additional Drivers`];

    return (
        <div className='price-card' style={{borderRadius: 8, backgroundColor: 'white', height: '100%', flex: 1, boxShadow: '0px 11px 30px 5px rgba(154,161,177,.1)', padding: '36px 32px'}}>
            <div>
                <h5 style={{textAlign: 'center'}}>{items ? 'FULLY MANAGED' : `${drivers} DRIVERS`}</h5>
                {!items ? <h3 style={{color: 'var(--bs-primary', textAlign: 'center', margin: '36px 0', fontWeight: 900}}>${price}<span style={{fontSize: 13}}>&nbsp;/mo</span></h3>
                : <h3 style={{color: 'var(--bs-primary', textAlign: 'center', margin: '36px 0', fontWeight: 900}}>Contact Us</h3>}
                <hr/>
                <ul style={{display: 'flex', flexDirection: 'column', gap: 12, padding: 0}}>
                    {defaultItems.map((item, i)=>{
                        return (
                            <li key={i} style={{display: 'flex', gap: 12, justifyContent: 'left', textAlign: 'left', alignItems: 'center'}}>
                                <FontAwesomeIcon color='var(--bs-primary)' icon={faCircleCheck}/>
                                <span>{item}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 28}}>
                {items ? <Button style={{textAlign: 'center', color: 'white', fontWeight: 'bold'}}>Call <a style={{color: 'white', textDecoration: 'none'}} href='rel:216-903-2147'>+1 (330) 522-1889</a></Button> : <Button onClick={()=>{window.open(recruitingUrl)}} style={{color: 'white', minWidth: 140, fontWeight: 'bold'}}>Sign Up</Button>}
            </div>
        </div>
    )
}